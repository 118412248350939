import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ProgressButton from 'modules/picasso-ui/form/ProgressButton';
import { useTranslation } from 'next-i18next';

const FormDialog = ({ open, title, message, onConfirm, onDismiss, onDismissClickaway, confirmButtonText, confirmDisabled, submitting, cancelDisabled }) => {
 
  const { t } = useTranslation('common')

  let confirmButtonTextEffective = confirmButtonText || t('action.confirm');



  return (
    <Dialog open={open} onClose={onDismissClickaway} fullWidth={true} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} disabled={cancelDisabled}>{t('action.cancel')}</Button>
        <ProgressButton color="primary" variant="contained" onClick={onConfirm} disabled={confirmDisabled} loading={submitting}>
          {confirmButtonTextEffective}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

const FormDialogContext = React.createContext({});

const FormDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState(null);
  const [confirmDisabled, setConfirmDisabled] = React.useState(null);
  const [payload, setPayload] = React.useState(null);
  const [submitting, setSubmitting] = useState(false);

  const openDialog = ({ title, message, confirmButtonText, actionCallback, cancelCallback, confirmDisabledDefault }) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, confirmButtonText, actionCallback, cancelCallback, confirmDisabledDefault });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
    setConfirmDisabled(null);
    setSubmitting(false);
  };

  const onConfirm = () => {
    setSubmitting(()=>true);
    dialogConfig.actionCallback(payload).then(()=> {
      resetDialog();
    });
  };

  const onDismiss = () => {
    resetDialog();
    if (dialogConfig.cancelCallback) {
      dialogConfig.cancelCallback();
    }
  };

  const onDismissClickaway = () => {
    if (submitting) {
      //clickaway not allowed while submitting
      return;
    }
    resetDialog();
    if (dialogConfig.cancelCallback) {
      dialogConfig.cancelCallback();
    }
  }

  const confirmDisabledEffective = () => {
    if (submitting) {
      return true;
    }
    if (confirmDisabled === null) {
      if (dialogConfig?.confirmDisabledDefault === undefined) {
        return false;
      } else {
        return dialogConfig?.confirmDisabledDefault;
      }
    }
    return confirmDisabled;
  };

  const openEffective = dialogOpen === true && dialogConfig != null;

  return (
    <FormDialogContext.Provider value={{ openDialog, setConfirmDisabled, setPayload }}>
      <FormDialog
        open={openEffective}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmButtonText={dialogConfig?.confirmButtonText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        onDismissClickaway={onDismissClickaway}
        confirmDisabled={confirmDisabledEffective()}
        cancelDisabled={submitting}
        submitting={submitting}
      />
      {children}
    </FormDialogContext.Provider>
  );
};

const useFormDialog = () => {
  const { openDialog, setConfirmDisabled, setPayload } = React.useContext(FormDialogContext);

  const getFormPayload = ({ ...options }) => {
    return new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });
  };

  return { getFormPayload, setConfirmDisabled, setPayload };
};

export default FormDialog;
export { FormDialogProvider, useFormDialog };
